<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<b-button
					v-if="$can('settlements', 'upload')"
					class="float-right mr-2"
					variant="primary"
					@click="goToUploadFile()">
					{{ translate('upload_file') }}
				</b-button>
			</div>
		</div>
		<hr>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th class="border-top-0">
							#
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('settlement_start_date')">
							{{ translate('settlement_start_date') }}
							<sort field="settlement_start_date" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('settlement_end_date')">
							{{ translate('settlement_end_date') }}
							<sort field="settlement_end_date" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('deposit_date')">
							{{ translate('deposit_date') }}
							<sort field="deposit_date" />
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('id')">
							{{ translate('settlement_id') }}
							<sort field="id" />
						</th>
						<th
							class="border-top-0 text-right pointer"
							@click="sortByField('total_amount')">
							{{ translate('total') }}
							<sort field="total_amount" />
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('status')">-->
							{{ translate('status') }}
							<!--<sort field="status" />-->
						</th>
						<th
							class="border-top-0">
							<!--class="border-top-0 pointer"-->
							<!--@click="sortByField('uploaded_by')">-->
							{{ translate('uploaded_by') }}
							<!--<sort field="uploaded_by" />-->
						</th>
						<th
							class="border-top-0 pointer"
							@click="sortByField('created_at')">
							{{ translate('date_time') }}
							<sort field="created_at" />
						</th>
						<th
							class="border-top-0 text-center">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td
							class="align-middle">
							{{ $moment(item.attributes.settlement_start_date.date).format(dateFormat) }}
						</td>
						<td
							class="align-middle">
							{{ $moment(item.attributes.settlement_end_date.date).format(dateFormat) }}
						</td>
						<td
							class="align-middle">
							{{ $moment(item.attributes.deposit_date.date).format(dateFormat) }}
						</td>
						<td class="align-middle">
							{{ item.attributes.settlement_id }}
						</td>
						<td
							class="align-middle text-right">
							{{ item.attributes.total_amount }}
						</td>
						<td
							class="align-middle">
							{{ translate(item.attributes.status) }}
						</td>
						<td class="align-middle">
							{{ item.attributes.user_id }}
						</td>
						<td
							class="align-middle">
							{{ $moment(item.attributes.upload_start_date.date).format(dateTimeFormat) }}
						</td>
						<td
							class="align-middle text-center">
							<b-button
								v-if="$can('settlements', 'view')"
								v-b-tooltip.hover
								:title="translate('view_details')"
								class="btn mx-1 bg-primary-alt"
								@click="viewDetails(item.id)">
								<i
									class="fa fa-eye"
									aria-hidden="true" />
							</b-button>
							<b-button
								v-if="ACTION_STATUSES.includes(item.attributes.status) && $can('settlements', 'approve')"
								v-b-tooltip.hover
								:title="translate('approve')"
								variant="success"
								class="btn mx-1 bg-success-alt"
								@click="approveSettlement(item.id)">
								<i
									class="fas fa-check"
									aria-hidden="true" />
							</b-button>
							<b-button
								v-if="ACTION_STATUSES.includes(item.attributes.status) && $can('settlements', 'approve')"
								v-b-tooltip.hover
								:title="translate('reject')"
								variant="danger"
								class="btn mx-1"
								@click="rejectSettlement(item.id)">
								<i
									class="fas fa-times"
									aria-hidden="true" />
							</b-button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import FiltersParams from '@/mixins/FiltersParams';
import { ACTION_STATUSES } from '@/settings/Settlements';
import { Grids, Settlement as SettlementMessages } from '@/translations';
import Order from '@/util/Order';
import { YMD_FORMAT, YMDHMS_FORMAT } from '@/settings/Dates';

export default {
	name: 'AmazonIndex',
	messages: [Grids, SettlementMessages],
	mixins: [FiltersParams],
	data() {
		return {
			order: new Order(),
			approve: new Order(),
			alert: new this.$Alert(),
			ACTION_STATUSES,
			dateFormat: YMD_FORMAT,
			dateTimeFormat: YMDHMS_FORMAT,
		};
	},
	computed: {
		loading() {
			return !!this.order.data.loading || !!this.approve.data.loading;
		},
		data() {
			try {
				const { data } = this.order.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		pagination() {
			return this.order.data.pagination;
		},
	},
	created() {
		this.getSettlements();
		this.getFiltersfromUrl();
	},
	methods: {
		viewDetails(settlementId) {
			this.$router.push({ name: 'OrdersSettlementDetails', params: { settlement_id: settlementId } });
		},
		approveSettlement(settlementId) {
			const trans = {
				title: this.translate('approve_title'),
				text: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
			};
			this.alert.confirmationWithPassword(trans.title, trans.text, options).then((password) => {
				const payload = { password: password.value };
				this.approve.approveSettlement(settlementId, payload).then(() => {
					this.alert.toast('success', this.translate('settlement_approved'));
					this.getSettlements();
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.approve.data.errors.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
						delete this.approve.data.errors.errors.password;
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
		rejectSettlement(settlementId) {
			this.alert.confirmation(this.translate('reject_title'), this.translate('reject_settlement')).then(() => {
				this.approve.rejectSettlement(settlementId).then(() => {
					this.getSettlements();
				});
			}).catch(() => {});
		},
		goToUploadFile() {
			this.$router.push({ name: 'UploadOrderFile' }).catch(() => {});
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}

			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			this.$router.push({ name: this.$route.name, query: options }).catch(() => {});
		},
		getSettlements() {
			this.order.getSettlements();
		},
	},
};
</script>
