const created = 'created';
const approved = 'approved';

export const SETTLEMENT_STATUSES = {
	created,
	approved,
};

export const ACTION_STATUSES = ['created'];

export default {};
